const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://34jj9eqjkj.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://beigia0a0g.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.staging.nsr.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.nsr.forwoodsafety.com',
    WEBSOCKET: 'wss://dvldmv3ov7.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;